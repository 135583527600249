<template>
  <div>
    <!-- The left-margin is there to compensate for the outline when the element is focused -->
    <SearchBar
      v-if="showSearchBar"
      ref="searchBar"
      v-bind="{ ...props, ...emitsAsProps }"
      style="margin-left: 2px"
      @on-clear="showSearchBar = false"
    />

    <FilterButton v-else size="icon" @click="showSearchBar = true" @keydown.s.prevent="showSearchBar = true">
      <ButtonIcon :icon="mapIcon.search" style="color: var(--gray-3)" />
    </FilterButton>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import type { Props, Emits } from "~/components/search/SearchBar.vue";
import { useEmitAsProps } from "radix-vue";

const props = defineProps<Props>();

const emits = defineEmits<Emits>();

const searchBar = ref(null);
onClickOutside(searchBar, () => {
  if (!search.value) showSearchBar.value = false;
});

const emitsAsProps = useEmitAsProps(emits);

const showSearchBar = ref(false);

const { modelValue: search } = useVModels(props);
</script>

<style scoped lang="scss"></style>
